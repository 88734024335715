<template>
  <div>
    <b-modal
      id="filtro"
      title="Selecione o período"
      ok-only
    >
      <b-row
        align-h="around"
        class="mb-2 mt-2"
      >
        <b-col cols="start">
          <b-row align-h="center">
            <h4>Inicio</h4>
          </b-row>
          <b-row>
            <b-form-input
              v-model="dateSelect.start"
              locale="pt"
              type="date"
            />
          </b-row>
        </b-col>
        <b-col cols="end">
          <b-row align-h="center">
            <h4>Fim</h4>
          </b-row>
          <b-row>
            <b-form-input
              v-model="dateSelect.end"
              locale="pt"
              type="date"
            />
          </b-row>
        </b-col>

      </b-row>
    </b-modal>

    <b-card>
      <b-card-header>
        <b-card-title>Totais</b-card-title>
        <b-button
          v-b-modal.filtro
          class="bg-info bg-darken-2"
        >
          Filtro
        </b-button>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            v-for="(item, index) in itemsCharts"
            :key="index"
            xl="3"
            sm="6"
            :class="item.customClass"
            @click="itemClick(index)"
          >
            <b-media
              no-body
              class="cursor-pointer"
            >
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BModal,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

} from 'bootstrap-vue'

import axios from '@/../axios-auth'

export default {
  components: {
    BCard,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BModal,
    BButton,
  },

  props: {
    selectCard: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      dateSelect: {
        start: '',
        end: '',
      },

      itemsCharts: [
        {
          color: 'light-primary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'PhoneCallIcon',
          subtitle: 'Ligações',
          title: '',
        },
        {
          color: 'light-info',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'DollarSignIcon',
          subtitle: 'Custo médio das ligações',
          title: '',
        },
        {
          color: 'light-success',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: '',
          subtitle: 'Acordos no mês',
          title: '',
        },
        {
          color: 'light-warning',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: '',
          subtitle: 'Credores',
          title: '',
        },

      ],

      totais: [],
    }
  },

  async created() {
    await this.totalLigacao()
  },

  methods: {
    updateCall(value) {
      this.itemsCharts[0].title = value
    },
    totalLigacao() {
      axios.get('api/v1/relatorios/backoffice/acoes/totais', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.totais = res.data.dados
        this.updateCall(res.data.dados)
      })
    },

    async itemClick(data) {
      if (data === 0) {
        await this.selectCard(0)
      }
    },

  },

}

</script>

<style>

</style>
