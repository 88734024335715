<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Grafico tabulações</b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="boilerplateCharts.latestBarChart.data"
        :options="boilerplateCharts.latestBarChart.options"
      />
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'
import boilerplateCharts from './boilerplateCharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ChartjsComponentBarChart,
  },

  data() {
    return {
      boilerplateCharts,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';

</style>
