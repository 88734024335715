<template>
  <div>
    <Totais :select-card="loadCard" />
    <div v-if="card === 0">
      <GraficoSentido />
      <GraficoTabulacao />
      <GraficoResultados />
    </div>
  </div>
</template>

<script>
// import {
//   BCard, BCardTitle, BCardSubTitle,
// } from 'bootstrap-vue'
import Totais from './Totais.vue'
import GraficoSentido from './GraficoSentido.vue'
import GraficoTabulacao from './GraficoTabulacao.vue'
import GraficoResultados from './GraficoResultados.vue'

export default {
  components: {
    Totais,
    GraficoSentido,
    GraficoTabulacao,
    GraficoResultados,
  },

  data() {
    return {
      card: '',
    }
  },

  methods: {
    loadCard(data) {
      if (this.card === data) {
        this.card = null
      }
      this.card = data
    },
  },

}
</script>

<style>

</style>
