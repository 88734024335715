import { $themeColors } from '@themeConfig'

import axios from '@/../axios-auth'

const sentidoData = []
const tabulacaoDataName = []
const tabulacaoDataCount = []
const resultData = []
let sumData = 0

// Dados da view de sentidos
axios.get('api/v1/relatorios/backoffice/acoes/sentido', {
  headers: {
    Authorization: 'Bearer '.concat(localStorage.getItem('token')),
    accept: 'application/json',
  },
}).then(res => {
  res.data.dados.map(dt => {
    sentidoData.push(dt.count)
  })

  // Soma os valores do array para obter o total sem precisar de requisição
  for (let i = 0; i < sentidoData.length; i++) {
    sumData += sentidoData[i]
  }
})

axios.get('api/v1/relatorios/backoffice/acoes/tabulacao', {
  headers: {
    Authorization: 'Bearer '.concat(localStorage.getItem('token')),
    accept: 'application/json',
  },
}).then(res => {
  res.data.dados.map(dt => {
    tabulacaoDataName.push(dt.tabulacao_nome)
    tabulacaoDataCount.push(dt.count)
  })
})

axios.get('api/v1/relatorios/backoffice/acoes/resultado', {
  headers: {
    Authorization: 'Bearer '.concat(localStorage.getItem('token')),
    accept: 'application/json',
  },
}).then(res => {
  res.data.dados.map(dt => {
    resultData.push(dt.count)
  })
})

// Colors
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
    series6: '#836AF9',
    series7: '#ffe800',
    series8: '#28dac6',
    series9: '#ffe802',
    series10: '#FDAC34',
    series11: '#299AFF',
    series12: '#4F5D70',
    series13: '#2c9aff',
    series14: '#84D0FF',
    series15: '#EDF1F4',
    series16: 'rgba(0, 0, 0, 0.25)',
    series17: '#666ee8',
    series18: '#ff4961',
    series19: '#6e6b7b',
    series20: 'rgba(200, 200, 200, 0.2)',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

// colors
const chartColorsTwo = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  // Gráfico de resultados
  donutChartResult: {
    series: resultData,
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
        chartColors.donut.series1,
        chartColors.donut.series2,
        chartColors.donut.series3,
        chartColors.donut.series4,
        chartColors.donut.series5,
        chartColors.donut.series6,
        chartColors.donut.series7,
        chartColors.donut.series8,
        chartColors.donut.series9,
        chartColors.donut.series10,
        chartColors.donut.series11,
        chartColors.donut.series12,
        chartColors.donut.series13,
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                // formatter(val) {
                //   // eslint-disable-next-line radix
                //   return `${parseInt(val)}%`
                // },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Total',
                formatter() {
                  return sumData
                },
              },
            },
          },
        },
      },
      labels: [
        'Hangup Cause: 3',
        'FALHA ORIGINATE',
        'CHANUNAVAIL',
        'LIG.MANUAL CANCELADA',
        'NAO ATENDE',
        'Hangup Cause: 58',
        'OCUPADO',
        'CONGESTION',
        'Hangup Cause: 22',
        'PROBLEMA DE ROTA DA OPERADORA',
        'NOT SURE',
        'MATOU',
        'LIGAÇÃO MUDA',
        'ELETRONICO',
        'DESLIGADO DURANTE DETECCAO',
        'FALHA NA REDE DA OPERADORA',
        'REJEITADA PELA OPERADORA',
        'NUMERO NÃO EXISTE',
        'ATENDIDA',
      ],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 480,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 420,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  },

  // Gráfico de Tabulações
  latestBarChart: {
    data: {
      labels: tabulacaoDataName,
      datasets: [
        {
          data: tabulacaoDataCount,
          backgroundColor: chartColorsTwo.successColorShade,
          borderColor: 'transparent',
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColorsTwo.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColorsTwo.grid_line_color,
              zeroLineColor: chartColorsTwo.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColorsTwo.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColorsTwo.grid_line_color,
              zeroLineColor: chartColorsTwo.grid_line_color,
            },
            ticks: {
              stepSize: 100,
              min: 0,
              max: 800,
              fontColor: chartColorsTwo.labelColor,
            },
          },
        ],
      },
    },
  },

  // Gráfico de Sentido
  donutChart: {
    series: sentidoData,
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
        // chartColors.donut.series1,
        // chartColors.donut.series5,
        chartColors.donut.series3,
        chartColors.donut.series2,
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                // formatter(val) {
                //   // eslint-disable-next-line radix
                //   return `${parseInt(val)}%`
                // },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Total',
                formatter() {
                  return sumData
                },
              },
            },
          },
        },
      },
      labels: ['Sainte', 'Entrante'],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  },

}
