<template>
  <b-card>
    <b-card-title class="mb-1">
      Sentido
    </b-card-title>
    <b-card-sub-title class="mb-2">
      Dados referentes a coluna sentido
    </b-card-sub-title>

    <vue-apex-charts
      type="donut"
      height="350"
      :options="boilerplateCharts.donutChart.chartOptions"
      :series="boilerplateCharts.donutChart.series"
    />

  </b-card>

</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import boilerplateCharts from './boilerplateCharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },

  data() {
    return {
      boilerplateCharts,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';

</style>
